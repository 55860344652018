import './public-path'
import { Storage } from '~/utils/storage'

declare const tixaOrigin: string

import styles from '../embed-helper.less'
const style = document.createElement('style')
style.innerHTML = styles
document.head.appendChild(style)

const getFrameByWindow = (
  windowEl: Window,
  callback: (HTMLIFrameElement) => void
) => {
  const elements = document.querySelectorAll(`iframe[src*="${tixaOrigin}"]`)
  elements.forEach((iframeEl) => {
    if ((iframeEl as HTMLIFrameElement).contentWindow === windowEl) {
      callback(iframeEl)
    }
  })
}

window.parent.addEventListener('message', (ev) => {
  if (ev.origin === tixaOrigin) {
    if (ev.data && ev.data.action) {
      if (ev.data.action === 'ping') {
        getFrameByWindow(ev.source as Window, () => {
          const params = {
            action: 'pong',
          }
          ;(ev.source as Window).postMessage(params, ev.origin)
        })
      } else if (ev.data.action === 'getAttrs') {
        getFrameByWindow(ev.source as Window, (frame) => {
          const params = {
            action: 'setAttrs',
            embedParent: document.location.href,
          }
          frame.getAttributeNames().forEach((name: string) => {
            params[name] = frame.getAttribute(name)
          })
          ;(ev.source as Window).postMessage(params, ev.origin)
        })
      } else if (ev.data.action === 'setHeight') {
        getFrameByWindow(ev.source as Window, (frame: HTMLIFrameElement) => {
          frame.style.height = `${ev.data.height + 2}px`
        })
      } else if (ev.data.action === 'setModal') {
        getFrameByWindow(ev.source as Window, (frame: HTMLIFrameElement) => {
          if (ev.data.open) {
            const placeholder = document.createElement('div')
            placeholder.style.height = `${frame.clientHeight}px`
            placeholder.style.width = `${frame.clientWidth}px`
            frame.insertAdjacentElement('afterend', placeholder)
            frame.classList.add('is-modal-open')
          } else {
            frame.style.height = `${frame.nextElementSibling.clientHeight}px`
            frame.classList.remove('is-modal-open')
            frame.parentElement.removeChild(frame.nextSibling)
          }
        })
      } else if (ev.data.action === 'submitPaymentForm') {
        const formContainer = document.createElement('div')
        formContainer.setAttribute('hidden', 'true')
        formContainer.innerHTML = ev.data.form
        document.body.appendChild(formContainer)

        const formElements = formContainer.getElementsByTagName('form')
        if (formElements.length > 0) {
          const formElement = formElements[0]
          formElement.submit()
        }
      }
    } else if (ev.data && ev.data.request && ev.data.id) {
      ev.data.answer = ''
      if (ev.data.request === 'setStore') {
        Storage.store(ev.data.args.key, ev.data.args.value, ev.data.args.local)
      } else if (ev.data.request === 'hasInStore') {
        ev.data.answer = Storage.hasInStore(
          ev.data.args.key,
          ev.data.args.local
        )
      } else if (ev.data.request === 'getFromStore') {
        ev.data.answer = Storage.getFromStore(
          ev.data.args.key,
          ev.data.args.local
        )
      } else if (ev.data.request === 'removeFromStore') {
        Storage.removeFromStore(ev.data.args.key, ev.data.args.local)
      }
      ;(ev.source as Window).postMessage(ev.data, ev.origin)
    }
  }
})
